import { $_app_server } from '@/_helpers'

export const projectService = {
  list,
  addProject,
  deleteProject,
  getAllProjectCustomer,
  addCustomerToProject
}

let f_resp = (response) => response.data;

function list() {
  return $_app_server.get("project/admins").then(f_resp)
}

function addProject(obj) {
  return $_app_server.post("project/add", obj).then(f_resp)
}

function deleteProject(id) {
  return $_app_server.delete(`project/removeProject/${id}`).then(f_resp)
}

function getAllProjectCustomer(projectId) {
  return $_app_server.get(`project/${projectId}/customers`).then(f_resp)
}

function addCustomerToProject(projectId, params) {
  return $_app_server.post(`project/${projectId}/addCustomerToProject`, params).then(f_resp)
}