import { config, $_app_server } from '@/_helpers'
import axios from "axios"

export const userService = {
  login,
  logout,
  get,
  check,
  list,
  listCheckProfiles,
  saveProfile,
  removeProfile,
  profile,
  merchs,
  coordinators,
  customers,
  getAllRoles,
  addUser,
  removeUser
  
}

function login (username, password) {
  const { id: client_id, secret: client_secret } = config.client
  const security = `grant_type=password&client_id=${client_id}&client_secret=${client_secret}&username=${username}&password=${password}`
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Authorization': 'Basic ' + btoa(client_id + ':' + client_secret)
  }
  
  return axios.post(config.authUrl, security, { headers })
  .then(response => {
    let { access_token, grants } = response.data
    localStorage.setItem('access_token', access_token)
    localStorage.setItem('grants', grants)
    $_app_server.defaults.headers.common.Authorization = 'Bearer ' + access_token
    return true
  })
  .catch(error => {
    if (error.response.status === 401) {
      logout()
      // location.reload(true)
      return false
    }
  })
}

function logout(redirect) {
  $_app_server.defaults.headers.common.Authorization = null
  localStorage.removeItem('access_token')
  localStorage.removeItem('grants')
  // location.reload(true)
  // location.href = config.host
  location.href = '/'
}

function get() {
  return $_app_server.get("user/get")
    .then(response => {
      return response.data
    })
    // .catch(error => {
    //   logout()
    // })
}

function check() {
  const loggedIn = localStorage.getItem('access_token');
  if (loggedIn) return true;
  return false;
}

function list() {
  return $_app_server.get(`user/list`).then((response) => response.data)
}

function listCheckProfiles() {
  return $_app_server.get(`user/list/profiles`).then((response) => response.data)
}

function saveProfile(profile) {
  return $_app_server.post(`user/profile`, profile).then((response) => response.data)
}

function removeProfile(id) {
  return $_app_server.delete(`user/${id}/profile`).then((response) => response.data)
}

function profile(id) {
  return $_app_server.get(`user/${id}/profile`).then((response) => response.data)
}

function merchs() {
  return $_app_server.get(`user/allmerchs`).then((response) => response.data)
}

function coordinators() {
  return $_app_server.get(`user/coordinators`).then((response) => response.data)
}

function customers() {
  return $_app_server.get(`user/customers`).then((response) => response.data)
}

function getAllRoles() {
  return $_app_server.get(`user/roles/list`).then((response) => response.data)
}

function addUser(obj) {
  return $_app_server.post(`user`, obj).then((response) => response.data)
}

function removeUser(id) {
  return $_app_server.delete(`user/${id}`).then((response) => response.data)
}